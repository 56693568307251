import React from "react"
import tw from "twin.macro"
import Logo from "../images/logo.svg"

const VidBox = tw.div`relative justify-center overflow-auto items-start flex h-28 flex-col bg-apana-plum xl:items-center`
const TheVideo = tw.video`absolute top-0 left-auto right-auto z-0 w-full h-full object-cover max-w-screen-xl`
const VidBoxTitle = tw.h1`z-10 pt-2`
const VidBoxLogo = tw.img`w-40 m-0 pl-6 xl:pl-0`

const Header = () => (
  <header>
    <VidBox>
      <TheVideo autoPlay muted loop playsInline>
        <source src={'/header.mp4'} type="video/mp4" />
      </TheVideo>
      <VidBoxTitle><VidBoxLogo src={Logo} /></VidBoxTitle>
    </VidBox>
  </header>
)

export default Header
